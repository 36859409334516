import config from '@config';
import { tl } from '@xFrame4/common/Utils';
import ALink from '@xFrame4/components/next/ALink';
import { FunctionComponent, useEffect, useState } from 'react';
import { useAppActionDispatch, useAppStateSelector } from '@redux/hooks';
import { setAppLanguage } from '@redux/layoutSlice';
import { useTranslate } from '@xFrame4/components/Hooks';
import { useRouter } from 'next/router';

interface LanguageSelectorProps
{

}

const LanguageSelector: FunctionComponent<LanguageSelectorProps> = (props) =>
{
    const t = useTranslate();
    const router = useRouter();
    const [showLanguageList, setShowLanguageList] = useState<boolean>(false);

    useEffect(() => {
        const clickHandler = (ev: MouseEvent) => {
            if (!(ev.target as HTMLElement).closest('.language-selector'))
            {
                setShowLanguageList(false);
            }
        }

        document.body.addEventListener('click', clickHandler);

        return () =>
        {
            document.removeEventListener('click', clickHandler);
        }; 
    }, [])
    
    /** Language menu items */
    let languageMenuItems = config.languages.map(language => {
        return (
            <div 
                key={language.code} 
                className="language-list-item px-3 py-1 border border-gray-500"
                onClick={() => {
                    router.push(config.appUrl + language.code);
                    setShowLanguageList(false);
                }}
            >
                {tl('APP_LANGUAGE', language.code)}
            </div>
        )
    });
    
    /** Render */
    return (
        <div className="language-selector cursor-pointer position-relative">
            <div 
                className="selected-language d-flex justify-content-end align-items-center"
                onClick={() => setShowLanguageList(!showLanguageList)}
            >
                <div className="me-1">{t('APP_LANGUAGE')}</div>
                <i className="fa-solid fa-chevron-down text-primary font-size-n75"></i>
            </div>
            <div className={'language-list flex-column bg-white pt-2 ' + (showLanguageList ? 'd-flex' : 'd-none')}>
                {languageMenuItems}
            </div>
        </div>
    );
}

export default LanguageSelector;