import { AppTracker } from '@business/tracking/AppTracker';
import { AppTrackerEvent, AppTrackerEventCategory } from '@business/common/Constants';
import { useAppActionDispatch } from '@redux/hooks';
import { setAllowCookies } from '@redux/trackerSlice';
import { useTranslate } from '@xFrame4/components/Hooks';
import { FunctionComponent, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

export const ALLOW_COOKIE_LOCAL_STORAGE_IDENTIFIER = 'allow_cookies';

interface CookieConsentProps
{

}

const CookieConsent: FunctionComponent<CookieConsentProps> = (props) =>
{
    const t = useTranslate();
    const dispatch = useAppActionDispatch();
    const [show, setShow] = useState<boolean>(false);

    /** Display logic */
    useEffect(() => {
        let alreadyAsked = localStorage.getItem(ALLOW_COOKIE_LOCAL_STORAGE_IDENTIFIER) != null;
        let allow = alreadyAsked && localStorage.getItem(ALLOW_COOKIE_LOCAL_STORAGE_IDENTIFIER) == '1';

        if (!alreadyAsked) 
        {
            setShow(true);
        }
        else
        {
            if (allow) dispatch(setAllowCookies(true));
        }
    }, []);

    /** Enable/disable cookies. */
    const allowCookies = (allow: boolean) => 
    {
        localStorage.setItem(ALLOW_COOKIE_LOCAL_STORAGE_IDENTIFIER, allow ? '1' : '0');
    }

    /** Accept button clicked. */
    const accept = () =>
    {
        allowCookies(true);
        setShow(false);
        dispatch(setAllowCookies(true));
        AppTracker.instance.logEvent(AppTrackerEvent.CookieConsentAccept, AppTrackerEventCategory.CookieConsent);
    }

    /** Deny button clicked. */
    const deny = () =>
    {
        allowCookies(false);
        setShow(false);
        dispatch(setAllowCookies(false));
    }

    /** Render */
    return (
        <div className="cookie-consent bg-white px-3 py-2 justify-content-end align-items-center" style={{ display: show ? 'block' : 'none' }}>
            <div className="row pb-env">
                <div className="cookie-consent-text col-lg-8 mb-2 mb-lg-0 d-flex align-items-center">
                    {t('GDPR_TEXT')}
                </div>
                <div className="cookie-consent-buttons col-lg-4 d-flex justify-content-center justify-content-lg-end py-2 pe-lg-5">
                    <Button
                        variant="primary"
                        className="cookie-consent-accept me-3"
                        onClick={() => accept()}
                    >
                        {t('GDPR_ACCEPT')}
                    </Button>

                    <Button
                        variant="secondary"
                        className="cookie-consent-deny"
                        onClick={() => deny()}
                    >
                        {t('GDPR_DENY')}
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default CookieConsent;