import { FooterMenuPosition, MenuItem } from '@components/menu/MenuBase';
import config, { AppLanguage } from '@config';
import { useAppStateSelector } from '@redux/hooks';
import AppSetting from '@xFrame4/business/AppSetting';
import { useTranslate } from '@xFrame4/components/Hooks';
import ALink from '@xFrame4/components/next/ALink';
import { FunctionComponent } from 'react';

interface FooterProps
{
    menuItems: MenuItem[];
    appSettings: AppSetting[];
}

const Footer: FunctionComponent<FooterProps> = (props) =>
{
    const t = useTranslate();
    const language = useAppStateSelector(state => state.layout.language);
    const facebookPage = AppSetting.getValueFromArray(props.appSettings, 'facebook_page');
    const instagramPage = AppSetting.getValueFromArray(props.appSettings, 'instagram_page');

    /** Social icons */
    let socialIcons = [
        /*
        {
            icon: 'fa-brands fa-facebook-f',
            link: facebookPage.url,
            alt: facebookPage.name
        },
        */
        {
            icon: 'fa-brands fa-instagram',
            link: instagramPage.url,
            alt: instagramPage.name
        },
        {
            icon: 'fa-regular fa-envelope',
            link: config.appUrl + language.code + '/contact' ,
            alt: t('EMAIL')
        },
    ];

    let cmpSocialIcons = socialIcons.map(socialIcon =>
        <div key={socialIcon.icon} className="footer-social-icon">
            <ALink 
                key={socialIcon.icon} 
                href={socialIcon.link} 
                target="_blank"
                className="text-white"
            >
                <i className={socialIcon.icon + ' me-4'}></i>
            </ALink>
        </div>
    );

    /** Menu items: Position 1 */
    let cmpPosition1MenuItems = props.menuItems.filter(menuItem => menuItem.footerPosition == FooterMenuPosition.Position1).map(menuItem => 
        <div key={menuItem.name} className="footer-menu-item me-4">
            <ALink 
                href={config.appUrl + language.code + '/' + menuItem.link}
                className="text-white"
            >
                {t(menuItem.name)}
            </ALink>
        </div>
    );

    /** Menu items: Position 2 */
    let cmpPosition2MenuItems = props.menuItems.filter(menuItem => menuItem.footerPosition == FooterMenuPosition.Position2).map(menuItem => 
        <div key={menuItem.name} className="footer-menu-item me-4">
            <ALink 
                href={config.appUrl + language.code + '/' + menuItem.link}
                className="text-white"
            >
                {t(menuItem.name)}
            </ALink>
        </div>
    );

    /** Render */
    return (
        <footer className="bg-primary text-white font-size-n85 pb-env">
            <div className="container py-5">
                <div className="row">
                    <div className='col-6 col-lg-8 mb-3'>
                        <div className="mb-3">{t('LOGO_TEXT')}</div>
                        <div className="mb-lg-3">
                            <ALink href={config.appUrl + language.code + '/contact'} className="text-white">
                                <i className="fa-regular fa-envelope me-3"></i>
                                {t('CONTACT')}
                            </ALink>
                        </div>
                        <div className="footer-menu-items-position-1  d-block d-lg-flex flex-wrap mb-3">
                            {cmpPosition1MenuItems}
                        </div>
                    </div>

                    <div className='col-6 col-lg-4'>
                        <div className="footer-social-icons d-flex mb-3">
                            {cmpSocialIcons}
                        </div>
                        <div className="footer-menu-items-position-2 d-block d-lg-flex flex-wrap mb-3">
                            {cmpPosition2MenuItems}
                        </div>
                        <div className="d-none d-lg-block">
                            {t('FOOTER_COPYRIGHT')}
                        </div>
                    </div>
                </div>
                <div className="d-flex d-lg-none justify-content-center mt-5">
                    {t('FOOTER_COPYRIGHT')}
                </div>
            </div>
        </footer>
    );
}



export default Footer;