import BusinessEntity, { EntityManager } from '@xFrame4/business/BusinessEntity';

export class AppSetting extends BusinessEntity
{
    name!: string;
    value: string = JSON.stringify({});
    isPublic: boolean = true;
    
    static manager: EntityManager<AppSetting> = new EntityManager<AppSetting>({
        name: 'AppSetting',
        createEntity: () => new AppSetting(),
        fields: [
            { name: 'name', isRequiredInput: true },
            { name: 'value', isRequiredInput: true },
            { name: 'isPublic', isRequiredInput: true },
        ],
        graphQlQueryAlias: 'appSettings',
    });

    /** 
     * Return the value as an object.
     */
    get valueAsObject()
    {
        return JSON.parse(this.value);
    }

    /**
     * Get the value for a setting.
     * 
     * @param name The setting name.
     */
    static async getValue(name: string): Promise<any | undefined | null>
    {
        let appSetting = await AppSetting.manager.get({ name: name });
        
        return appSetting != null ? appSetting.valueAsObject : undefined;
    }

    /**
     * Set the value for a setting.
     * 
     * @param name The setting name.
     * @param value The setting value.
     */
    static async setValue(name: string, value: any)
    {
        let appSetting = await AppSetting.manager.get({ name: name });

        if (appSetting == null)
        {
            appSetting = new AppSetting();
            appSetting.name = name;
        }
        
        appSetting.value = JSON.stringify(value);
        
        return await appSetting.save();
    }

    /**
     * Delete a setting.
     * 
     * @param name The setting name.
     */
    static async delete(name: string)
    {
        let appSetting = await AppSetting.manager.get({ name: name });
        if (appSetting != null) await appSetting.delete();
    }

    /**
     * Set the visibility (public or not) for a setting.
     * 
     * @param name The setting name.
     * @param isPublic Is it visible without logging in as an admin?
     */
    static async setVisibility(name: string, isPublic: boolean)
    {
        let appSetting = await AppSetting.manager.get({ name: name });
        if (appSetting != null)
        {
            appSetting.isPublic = isPublic;
            await appSetting.save();
        }
    }

    /**
     * Get a setting value from an array of settings.
     * 
     * @param appSettings An array of AppSetting objects.
     * @param name The name of the setting.
     * @returns The value as object.
     */
    static getValueFromArray(appSettings: AppSetting[], name: string)
    {
        let appSetting = appSettings.find(s => s.name == name);
        if (appSetting)
        {
            return appSetting.valueAsObject;
        }
        else
        {
            return undefined;
        }
    }
}

export default AppSetting;