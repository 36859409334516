import { MenuItem, MenuPosition } from '@components/menu/MenuBase';
import MobileMenu from '@components/menu/MobileMenu';
import AppSetting from '@xFrame4/business/AppSetting';
import { FunctionComponent, useState } from 'react';
import Footer from '../footer/Footer';
import CookieConsent from '../gdpr/CookieConsent';
import Header from '../header/Header';

interface LayoutProps
{
    children: JSX.Element | JSX.Element[];
    menuItems: MenuItem[];
    appSettings: AppSetting[];
}

const Layout: FunctionComponent<LayoutProps> = (props) =>
{
    /** Render */
    return (
        <>
            <MobileMenu 
                menuItems={props.menuItems} 
            />
            
            <Header 
                menuItems={props.menuItems.filter(menuItem => menuItem.position == MenuPosition.Both || menuItem.position == MenuPosition.Header)} 
                appSettings={props.appSettings}
            />
            
            <div className="container page py-4">
                {props.children}
            </div>
            
            <Footer
                menuItems={props.menuItems.filter(menuItem => menuItem.position == MenuPosition.Both || menuItem.position == MenuPosition.Footer)} 
                appSettings={props.appSettings}
            />

            <CookieConsent />
        </>
    );
}

export default Layout;