import { BootstrapBreakpoint } from '@xFrame4/common/Constants';
import { freshImageUrl, isElementAboveThreshold } from '@xFrame4/common/Functions';
import React from 'react';
import { CSSProperties, FunctionComponent, useEffect, useRef, useState } from 'react';

export type LazyLoadBreakpoint = {
    /** A Bootstrap breakpoint. */
    minBreakpoint: BootstrapBreakpoint;
    /** Should the image be loaded if the screen width is above the minBreakpoint? */
    load: boolean
}

interface SmoothImageProps
{
    src: string;
    enableLazyLoading?: boolean;
    /** How close (in pixels) the image must be to the bottom of the window to load the image. Good for offscreen images. */
    lazyLoadPixelTreshold?: number;
    /** Specify lazy load based on device screen width in Bootstrap breakpoint style. Good for hidden images. */
    lazyLoadBreakpoints?: LazyLoadBreakpoint[];
    width?: number;
    height?: number;
    className?: string;
    style?: CSSProperties;
    alt?: string;
    /** Create an image URL that forces the browser to refresh the image each time */
    createFreshImageUrl?: boolean;
    /** Fired when the image has been loaded. */
    onLoaded?: () => void;
    onClick?: () => void;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
}

/**
* Get the image src from the props.src and modify it if necessary. 
* (for now: only the fresh image URL can be applied) 
*/
function getImageSrc(imageSrc: string, createFreshImageUrl?: boolean)
{
    return createFreshImageUrl ? freshImageUrl(imageSrc) : imageSrc;
}

/**
 * Renders an image that loads smoothly, lazily and responsively.
 * 
 * @param props 
 */
const SmoothImage: FunctionComponent<SmoothImageProps> = (props) =>
{
    const img = useRef<HTMLImageElement>(null);
    const [src, setSrc] = useState<string>(props.enableLazyLoading ? '' : getImageSrc(props.src, props.createFreshImageUrl));
    const [triggerLazyLoading, setTriggerLazyLoading] = useState<boolean>(!props.enableLazyLoading);
    const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false);



    /** If props.src changes: the src state will only change if the previous src has been already loaded. */
    useEffect(() =>
    {
        if (isImageLoaded) setSrc(getImageSrc(props.src, props.createFreshImageUrl))
    }, [props.src]);

    /** Check if the HTMLImageElement has been loaded. */
    useEffect(() =>
    {
        if (img.current?.complete === true)  
        {
            setIsImageLoaded(true);
            if (props.onLoaded) props.onLoaded();
        }
    }, [img.current?.complete])

    /** Lazy load check on scroll. Does not work if lazyLoadBreakpoints are set. */
    useEffect(() =>
    {
        const onScroll = () =>
        {
            if (isElementAboveThreshold(img.current as HTMLImageElement, props.lazyLoadPixelTreshold!))
            {
                if (props.lazyLoadBreakpoints === undefined) setTriggerLazyLoading(true);
            }
        };
        document.addEventListener('scroll', onScroll);

        //cleanup: remove scroll event listener
        return () =>
        {
            document.removeEventListener('scroll', onScroll);
        };
    }, []);

    /** 
     * Load the image if lazy loading is enabled and it's already above the screen bottom when loading the page. 
     * Does not work if lazyLoadBreakpoints are set.
     * Sometimes a minHeight has to be set via the props.style property, because browsers do not respect the aspect ratio early on when loading the page (the height is very small), and lazy load will be triggered for the other images on the page (because they will be in the screen).
     */
    useEffect(() =>
    {

        if (props.enableLazyLoading && isElementAboveThreshold(img.current as HTMLImageElement, 0))
        {
            if (props.lazyLoadBreakpoints === undefined) setTriggerLazyLoading(true);
        }
    }, []);

    /** Load the image if lazy loading is enabled and the lazy load breakpoint settings enable it. */
    useEffect(() =>
    {
        if (props.lazyLoadBreakpoints != undefined && props.lazyLoadBreakpoints.length > 0)
        {
            // Get the screen width
            let windowWidth = window.innerWidth;

            // Get all breakpoints that are less than the screen width and sort them ASC
            let lazyLoadBreakpoints = props.lazyLoadBreakpoints
                .sort((a, b) => a.minBreakpoint - b.minBreakpoint)
                .filter(bp => bp.minBreakpoint <= windowWidth);

            // If the largest filtered breakpoint enables the lazy loading: trigger the lazy loading
            if (lazyLoadBreakpoints.length > 0 && lazyLoadBreakpoints[lazyLoadBreakpoints.length - 1].load)
            {
                setTriggerLazyLoading(true);
            }
        }
    }, []);

    /** Trigger the lazy loading, set the src from the img. */
    useEffect(() =>
    {
        if (triggerLazyLoading) setSrc(getImageSrc(props.src, props.createFreshImageUrl));
    }, [triggerLazyLoading]);

    /** Render */
    return (
        <img
            src={src}
            style={{
                ...props.style,
                transition: 'opacity',
                transitionDuration: props.enableLazyLoading ? '300ms' : '0s',
                opacity: !props.enableLazyLoading ? 1 : (isImageLoaded ? 1 : 0)
            }}
            className={'smooth-image ' + props.className}
            ref={img}
            width={props.width}
            height={props.height}
            alt={props.alt}
            onClick={props.onClick}
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave}
        />
    );
}

SmoothImage.defaultProps = {
    enableLazyLoading: false,
    lazyLoadPixelTreshold: 300,
    className: '',
    style: {}
}

export default SmoothImage;