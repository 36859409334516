import { gql } from '@apollo/client';
import config from '@config';
import client from '@graphql/apollo-client';

declare let window: any;

export enum TrackerEcommerceEventType
{
    ViewProducts,
    AddToWishList,
    AddToCart,
    RemoveFromCart,
    ViewCart,
    InitiateCheckout,
    AddPaymentInfo,
    Purchase,
    Refund
}

/** Tracking info for a product */
export interface TrackerProduct
{
    id: number | string;
    name: string;
    price?: number;
    quantity?: number;
    category?: string;
    brand?: string;
    variant?: string,
    discount?: number;
    coupon?: number;
}

/** Tracking data for an eCommerce event. */
export interface TrackerEcommerceData
{
    type: TrackerEcommerceEventType,
    value: number;
    currency: string;
    products: TrackerProduct[];
    couponCode?: string;
    transactionId?: number | string;
    shippingCost?: number;
    tax?: number;
    paymentType?: string;
}

/** Extra user info for  enhanced Google Ads and/or Facebook Conversions API. */
export interface TrackerExtraUserData 
{
    emails?: string[],
    phoneNumbers?: string[]
}

/**
 * Track user interactions with the app.
 */
export class Tracker
{
    protected static _instance?: Tracker;
    enabled = true;
    trackWithGoogle = false;
    trackWithFacebook = false;
    trackWithFacebookConversionsApi = false;
    gtag?: Gtag.Gtag;
    fbq?: facebook.Pixel.Event;

    // Singleton
    protected constructor()
    {
        this.initGoogle();
        this.initFacebook();
    }

    static get instance(): Tracker
    {
        if (this._instance === undefined) this._instance = this.createInstance();

        return this._instance;
    }

    protected static createInstance(): Tracker
    {
        return new Tracker();
    }

    /**
    * Init the Google Analytics tracking code.
    * https://developers.google.com/analytics/devguides/collection/gtagjs
    * https://developers.google.com/tag-platform/tag-manager/web/datalayer
    * https://support.google.com/google-ads/answer/7548399
    * https://support.google.com/google-ads/answer/12785474?visit_id=638155969923163305-1440919079&rd=1#
    */
    private initGoogle()
    {
        // Load the tracking script
        let script = document.createElement("script");
        script.src = 'https://www.googletagmanager.com/gtag/js?id=' + config.googleAnalyticsId;
        script.async = true;
        document.body.appendChild(script);

        // Initialize the data layer (events in the data layer will be sent to Google as soon as the gtag script has been loaded)
        window.dataLayer = window.dataLayer || [];
        this.gtag = function () { window.dataLayer.push(arguments); }
        this.gtag('js', new Date());
        this.gtag('config', config.googleAnalyticsId, { 'send_page_view': false });

        // Google Ads tag
        if (config.googleAdsId != null)
        {
            this.gtag('config', config.googleAdsId, { 'allow_enhanced_conversions': true });
        }
    }

    /**
    * Initialize Facebook pixel code.
    * https://www.facebook.com/business/help/952192354843755?id=1205376682832142
    */
    private initFacebook()
    {
        const f = function (f?: any, b?: any, e?: any, v?: any, n?: any, t?: any, s?: any)
        {
            if (f.fbq) return;
            n = f.fbq = function ()
            {
                n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
            };
            if (!f._fbq) f._fbq = n;
            n.push = n;
            n.loaded = !0;
            n.version = '2.0';
            n.queue = [];
            t = b.createElement(e);
            t.async = !0;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s)
        };
        f(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
        //fbq('set', 'autoConfig', 'false', config.facebookPixelID); 
        fbq('init', config.facebookPixelId);
        this.fbq = fbq;
    }

    /**
    * Log a custom event.
    * https://developers.google.com/analytics/devguides/collection/ga4/events?client_type=gtag
    * https://developers.facebook.com/docs/meta-pixel/implementation/conversion-tracking
    * 
    * @param name 
    * @param parameters 
    * @param extraUserData
    */
    logEvent(name: string, parameters: any = {}, extraUserData?: TrackerExtraUserData)
    {
        // Google
        this.logGoogleEvent(name, parameters);

        // Facebook
        this.logFacebookEvent(name, parameters);

        // Facebook Conversions API
        this.logFacebookConversionsApiEvent(name, window?.location?.href, parameters, extraUserData);
    }

    /**
    * Log a page view (Google only).
    * https://developers.google.com/analytics/devguides/collection/gtagjs/pages
    * https://developers.facebook.com/docs/meta-pixel/reference#standard-events
    */
    logPageView(pageTitle: string = document.title, pageUrl: string = location.href, extraUserData?: TrackerExtraUserData)
    {
        // Google
        let parameters: any = {
            page_title: pageTitle,
            page_location: pageUrl,
        };
        this.logGoogleEvent('page_view', parameters);

        // Facebook
        parameters = {
            content_name: pageTitle,
        };
        this.logFacebookEvent('ViewContent', parameters);

        // Facebook Conversions API
        this.logFacebookConversionsApiEvent('ViewContent', pageUrl, parameters, extraUserData);
    }

    /**
     * Log an eCommerce related event.
     * 
     * @param data eCommerce data
     * @param extraUserData
     */
    logEcommerceEvent(data: TrackerEcommerceData, extraUserData?: TrackerExtraUserData)
    {
        // Google data
        // https://developers.google.com/analytics/devguides/collection/ga4/ecommerce
        // https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#purchase
        let googleData = {
            transaction_id: data.transactionId,
            value: data.value,
            tax: data.tax,
            shipping: data.shippingCost,
            currency: data.currency,
            coupon: data.couponCode,
            payment_type: data.paymentType,
            items: data.products.map(product => ({
                item_id: product.name,
                item_name: product.name,
                price: product.price,
                quantity: product.quantity,
                item_category: product.category,
                item_brand: product.brand,
                item_variant: product.variant
            }))
        };

        // Facebook data
        // https://developers.facebook.com/docs/meta-pixel/reference#standard-events
        let facebookData = {
            currency: data.currency,
            value: data.value,
            content_type: 'product',
            contents: data.products.map(product => ({
                id: product.id,
                title: product.name,
                item_price: product.price,
                quantity: product.quantity,
            }))
        };

        // Event name
        let googleEventName: string = '';
        let facebookEventName: string = '';
        switch (data.type)
        {
            case TrackerEcommerceEventType.ViewProducts:
                {
                    googleEventName = 'view_item';
                    facebookEventName = 'ViewContent';
                }
                break;
            case TrackerEcommerceEventType.AddToWishList:
                {
                    googleEventName = 'add_to_wishlist';
                    facebookEventName = 'AddToWishlist';
                }
                break;
            case TrackerEcommerceEventType.AddToCart:
                {
                    googleEventName = 'add_to_cart';
                    facebookEventName = 'AddToCart';
                }
                break;
            case TrackerEcommerceEventType.RemoveFromCart:
                {
                    googleEventName = 'remove_from_cart';
                    facebookEventName = 'RemoveFromCart';
                }
                break;
            case TrackerEcommerceEventType.ViewCart:
                {
                    googleEventName = 'view_cart';
                    facebookEventName = 'ViewCart';
                }
                break;
            case TrackerEcommerceEventType.InitiateCheckout:
                {
                    googleEventName = 'begin_checkout';
                    facebookEventName = 'InitiateCheckout';
                }
                break;
            case TrackerEcommerceEventType.AddPaymentInfo:
                {
                    googleEventName = 'add_payment_info';
                    facebookEventName = 'AddPaymentInfo';
                }
                break;
            case TrackerEcommerceEventType.Purchase:
                {
                    googleEventName = 'purchase';
                    facebookEventName = 'Purchase';
                }
                break;
            case TrackerEcommerceEventType.Refund:
                {
                    googleEventName = 'refund';
                    facebookEventName = 'Refund';
                }
                break;
        }

        // Log event
        this.logGoogleEvent(googleEventName, googleData);
        this.logFacebookEvent(facebookEventName, facebookData);
        this.logFacebookConversionsApiEvent(facebookEventName, window?.location?.href, facebookData, extraUserData);
    }

    /**
    * Log a Google event with gtag.js.
    * https://developers.google.com/analytics/devguides/collection/gtagjs/events
    * https://developers.google.com/analytics/devguides/collection/ga4/events?client_type=gtag
    * 
    * @param name Event name. 
    * @param parameters Event parameters.
    */
    private logGoogleEvent(name: string, parameters: any = {})
    {
        if (!this.enabled || !this.trackWithGoogle || this.gtag === undefined) return;

        if (parameters == null) parameters = {};
        parameters.version = config.version;

        this.gtag('event', name, parameters);
    }

    /**
     * Log a Google Ads conversion event.
     * https://support.google.com/google-ads/answer/7548399 (Event snippet)
     * https://support.google.com/google-ads/answer/12785474?visit_id=638155969923163305-1440919079&rd=1# (Enhanced conversions)
     * 
     * @param conversionLabel The conversion label
     * @param parameters The conversion parameters.
     */
    logGoogleAdsEvent(conversionLabel: string, parameters: any = {}, extraUserData?: TrackerExtraUserData)
    {
        if (!this.enabled || !this.trackWithGoogle || this.gtag === undefined) return;

        if (parameters == null) parameters = {};
        parameters.version = config.version;

        // Google Ads enhanced conversion data
        if (extraUserData !== undefined && this.gtag !== undefined)
        {
            this.gtag('set', 'user_data', {
                email: extraUserData.emails,
                phone_number: extraUserData.phoneNumbers
            });
        }

        // Log the event
        this.logGoogleEvent('conversion', {
            send_to: config.googleAdsId + '/' + conversionLabel,
            ...parameters
        });
    }

    /**
    * Log a Facebook pixel event via JS
    * https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking/
    * 
    * @param name Event name. 
    * @param parameters Event parameters.
    */
    private logFacebookEvent(name: string, parameters: any = {})
    {
        if (!this.enabled || !this.trackWithFacebook || this.fbq === undefined) return;

        if (parameters == null) parameters = {};
        parameters.version = config.version;

        this.fbq('trackCustom', name, parameters);
    }

    /**
     * Log an event with the Facebook Conversion API.
     * 
     * @param name Event name.
     * @param sourceUrl The site URL where the event happened.
     * @param parameters Event parameters.
     */
    private async logFacebookConversionsApiEvent(name: string, sourceUrl: string, parameters: any = {}, extraUserData?: TrackerExtraUserData)
    {
        if (!this.enabled || !this.trackWithFacebookConversionsApi) return;

        let mutation = `
            mutation TrackWithFacebookConversionsApi($input: FacebookConversionApiInput!) {
                trackWithFacebookConversionsApi(input: $input)
            }
        `;

        let input = {
            eventName: name,
            eventSourceUrl: sourceUrl,
            parameters: JSON.stringify(parameters),
            userParameters: JSON.stringify({
                emails: extraUserData?.emails ?? [],
                phones: extraUserData?.phoneNumbers ?? []
            })
        };

        let { data } = await client.mutate({
            mutation: gql(mutation),
            variables: {
                input: input
            }
        });

        return data.trackWithFacebookConversionsApi as boolean;
    }
}

export default Tracker;