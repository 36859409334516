import config from '@config';
import ALink from '@xFrame4/components/next/ALink';
import { FunctionComponent } from 'react';
import { MenuBaseProps } from './MenuBase';
import { useAppActionDispatch, useAppStateSelector } from '@redux/hooks';
import { useTranslate } from '@xFrame4/components/Hooks';

interface DesktopMenuProps extends MenuBaseProps
{

}

const DesktopMenu: FunctionComponent<DesktopMenuProps> = (props) =>
{
    const t = useTranslate();
    const language = useAppStateSelector(state => state.layout.language);
    
    /** Menu items */
    let cmpMenuItems = props.menuItems.map(menuItem =>
        <div 
            key={menuItem.name} 
            className="desktop-menu-item text-uppercase fw-bold ms-5"
        >
            <ALink 
                href={config.appUrl + language.code + '/' + menuItem.link}
                className="text-white"
            >
                    {t(menuItem.name)}
            </ALink>
        </div>
    );
    
    /** Render */
    return (
        <div className="desktop-menu d-flex justify-content-end align-items-center py-1">
            {cmpMenuItems}
        </div>
    );
}

export default DesktopMenu;