export enum AppTrackerEvent
{
    CookieConsentShow = 'cookie_consent_show',
    CookieConsentAccept = 'cookie_consent_allow',
    CookieConsentDeny = 'cookie_consent_deny'
}

export enum AppTrackerEventCategory
{
    CookieConsent = 'cookie_consent',
}