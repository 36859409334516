import { FunctionComponent } from 'react';

export enum MenuPosition
{
    Header,
    Footer,
    Both
}

export enum FooterMenuPosition
{
    Position1,
    Position2
}

export interface MenuItem
{
    name: string;
    link: string;
    position: MenuPosition;
    footerPosition?: FooterMenuPosition
}

export interface MenuBaseProps
{
    menuItems: MenuItem[];
}

const MenuBase: FunctionComponent<MenuBaseProps> = (props) =>
{
    /** Render */
    return (
        <>
        </>
    );
}

export function createMenuItems()
{
    let menuItems: MenuItem[] = [
        {
            name: 'ABOUT',
            link: 'about',
            position: MenuPosition.Both,
            footerPosition: FooterMenuPosition.Position2
        },
        {
            name: 'PORTFOLIO',
            link: 'portfolio',
            position: MenuPosition.Both,
            footerPosition: FooterMenuPosition.Position2
        },
        {
            name: 'BIOGRAPHY',
            link: 'biography',
            position: MenuPosition.Both,
            footerPosition: FooterMenuPosition.Position2
        },
        {
            name: 'EXHIBITIONS',
            link: 'exhibitions',
            position: MenuPosition.Both,
            footerPosition: FooterMenuPosition.Position2
        },
        {
            name: 'CONTACT',
            link: 'contact',
            position: MenuPosition.Both,
            footerPosition: FooterMenuPosition.Position2
        },
        {
            name: 'IMPRESSUM',
            link: 'impressum',
            position: MenuPosition.Footer,
            footerPosition: FooterMenuPosition.Position1
        },
        {
            name: 'PRIVACY_POLICY',
            link: 'privacy',
            position: MenuPosition.Footer,
            footerPosition: FooterMenuPosition.Position1
        }
    ];

    return menuItems;
}

export default MenuBase;