import config from '@config';
import { useAppActionDispatch, useAppStateSelector } from '@redux/hooks';
import { setIsMobileMenuOpen } from '@redux/layoutSlice';
import { useTranslate } from '@xFrame4/components/Hooks';
import { useSetStateFromProp } from '@xFrame4/components/Hooks';
import ALink from '@xFrame4/components/next/ALink';
import { FunctionComponent, useEffect, useState } from 'react';
import { MenuBaseProps, MenuPosition } from './MenuBase';

interface MobileMenuProps extends MenuBaseProps
{
    
}

const MobileMenu: FunctionComponent<MobileMenuProps> = (props) =>
{
    const t = useTranslate();
    const dispatch = useAppActionDispatch();
    const isMobileMenuOpen = useAppStateSelector(state => state.layout.isMobileMenuOpen);
    const language = useAppStateSelector(state => state.layout.language);

    /** Disable body scroll when menu is open */
    useEffect(() => {
        if (isMobileMenuOpen)
        {
            document.documentElement.style.overflow = 'hidden';
            document.body.style.overflow = 'hidden';
        }
        else
        {
            document.documentElement.style.overflow = 'auto';
            document.body.style.overflow = 'auto';
        }
    }, [isMobileMenuOpen])

    /** Menu items */
    let cmpMenuItems = props.menuItems.filter(menuItem => menuItem.position == MenuPosition.Both || menuItem.position == MenuPosition.Header).map(menuItem =>
        <div 
            key={menuItem.name} 
            className="mobile-menu-item text-uppercase text-center fw-bold font-size-5 mb-4"
        >
            <ALink 
                href={config.appUrl + language.code + '/' + menuItem.link}
                className="text-white"
                onClick={() => dispatch(setIsMobileMenuOpen(false))}
            >
                    {t(menuItem.name)}
            </ALink>
        </div>
    );
    
    /** Render */
    return (
        <div className={'mobile-menu ' + (isMobileMenuOpen ? 'mobile-menu-state-open' : '')}>
            <div className="mobile-menu-inner bg-primary text-white w-100 h-100">
                <div className='mobile-menu-top d-flex justify-content-end'>
                    <div 
                        className="py-1 pe-3"
                        onClick={() => dispatch(setIsMobileMenuOpen(false))}
                    >
                        <i className="fa-solid fa-xmark text-white font-size-5"></i>
                    </div>
                </div>
                <div className="mobile-menu-items d-flex flex-column pt-5">
                    {cmpMenuItems}
                </div>
            </div>
        </div>
    );
}

export default MobileMenu;