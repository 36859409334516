import { FunctionComponent, useEffect } from 'react';
import { useTranslate } from '@xFrame4/components/Hooks';
import config, { AppLanguage } from '@config';
import { useAppActionDispatch, useAppStateSelector } from '@redux/hooks';
import { AppTracker } from '@business/tracking/AppTracker';
import SmoothImage from '@xFrame4/components/SmoothImage';
import LanguageSelector from './LanguageSelector';
import DesktopMenu from '@components/menu/DesktopMenu';
import { MenuItem } from '@components/menu/MenuBase';
import { useRouter } from 'next/router';
import { setIsMobileMenuOpen } from '@redux/layoutSlice';
import AppSetting from '@xFrame4/business/AppSetting';
import ALink from '@xFrame4/components/next/ALink';

interface HeaderProps
{
    menuItems: MenuItem[];
    appSettings: AppSetting[];
}

const Header: FunctionComponent<HeaderProps> = (props) =>
{
    const t = useTranslate();
    const router = useRouter();
    const dispatch = useAppActionDispatch();
    const instagramPage = AppSetting.getValueFromArray(props.appSettings, 'instagram_page');
    const allowCookies = useAppStateSelector(state => state.tracker.allowCookies);

    /** Track pageview */
    useEffect(() => {
        if (allowCookies) AppTracker.instance.logPageView();
    }, [allowCookies]);

    /** Social icons */
    let socialIcons = [
        {
            icon: 'fa-brands fa-instagram',
            link: instagramPage.url,
            alt: instagramPage.name
        }
    ];

    let cmpSocialIcons = socialIcons.map(socialIcon =>
        <div key={socialIcon.icon} className="header-social-icon">
            <ALink 
                key={socialIcon.icon} 
                href={socialIcon.link} 
                target="_blank"
                className="text-gray-600 font-size-4"
            >
                <i className={socialIcon.icon + ''}></i>
            </ALink>
        </div>
    );

    /** Render */
    return (
        <header>
            <div className="container">
                <div className="header-top">
                    <div className="row">
                        <div className="col-2 d-lg-none"></div>
                        <div 
                            className="logo-container col-8 col-lg-10 d-flex align-items-center justify-content-center justify-content-lg-start py-1 cursor-pointer"
                            onClick={() => router.push(config.appUrl)}
                        >
                            <SmoothImage 
                                src={config.defaultPublicDirBaseUrl + 'img/logo/logo.png'}
                                width={150}
                                height={150}
                                className="logo me-2"
                            />
                            <span className="logo-text text-uppercase fw-bold font-size-3">{t('LOGO_TEXT')}</span>
                        </div>
                        <div className="col-2 d-flex justify-content-end align-items-center">
                            <div className="header-social-icons d-none d-md-block me-3">
                                {cmpSocialIcons}
                            </div>
                            <LanguageSelector />
                        </div>
                    </div>
                </div>
            </div>
            <div className="header-bottom bg-primary py-1">
                <div className="container">
                    <div className="d-none d-lg-block">
                        <DesktopMenu menuItems={props.menuItems} />
                    </div>
                    <div className="d-flex d-lg-none justify-content-end py-1">
                        <i className="fa-solid fa-bars text-white font-size-4" onClick={() => dispatch(setIsMobileMenuOpen(true))}></i>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;